import React, { useState, useEffect } from 'reactn';
import { Button, Upload, Spin } from 'antd';
import xlxs from 'xlsx';
import { UploadOutlined } from '@ant-design/icons';

export default (props) => {
  let { setExcelData,isDSRList ,isDSRAdminList} = props;
  let [files, setFiles] = useState([]);
  let [, setLastFile] = useState(null);
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    if (files.length === 0) return;
    setFiles([]);
    setLoading(true);
    var reader = new FileReader();

    reader.onload = async function (e) {
      let wb = xlxs.read(e.target.result, { type: 'array' });
      let ws = wb.Sheets[wb.SheetNames[0]];
      let json = xlxs.utils.sheet_to_json(ws);
      setLastFile(e.target.result);
      setExcelData(json);
      setLoading(false);
    };

    reader.readAsArrayBuffer(files[0]);
  }, [files]);

  return (
    <Upload
      beforeUpload={(file) => {
        setFiles([...files, file]);
        return false;
      }}
      fileList={files}
      multiple={false}
    >
      <Button
        type="success"
        size="large"
        disabled={loading}
        loading={loading}
        icon={<UploadOutlined />}
      >
        {isDSRList && !isDSRAdminList ? "Import DSR" : (isDSRAdminList  ? "Import DSR Admin" : "Import Excel")}
        
      </Button>
      {files.length > 10 && <Spin />}
    </Upload>
  );
};
