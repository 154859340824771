import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm,message,Form,Typography,InputNumber} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';
import { ImportExcel } from './../components';
import ExportJsonExcel from 'js-export-excel';
import {DownloadOutlined,EditOutlined, DeleteOutlined, PlusOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useParams} from 'react-router-dom'

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
const PriceList = (props) => {
    let params = useParams()
    let id = params.id;
  
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    let [unchangedData, setUnchangedData] = useState([])

    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;
    
    let [excelData, setExcelData] = useState([]);
    let [filterSubCategory, setFilterSubCategory] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [isSmall,] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 
    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let [user,] = useGlobal("user");
    let [module] = modules.filter(el => el.refId === path);
    const {Search} = Input;

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order,-createdAt';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }
        let query = [];
        query["module"] = module ? module._id : "";
        query["parent"] = null;
        let _params = {sort: shortString, ...page,query, module: (module ? module._id : "")};


        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }
        let filterSubCategory=[];
        let restData = await api.get('/api/priceList').then(({data}) => {
            if (data.result) {
                setTotalCount(data.result.total);
                return data.result.map((item, key) => {
                  if(item.subCategory){
                    let filtre={
                            text: item.subCategory,
                            value: item.subCategory, 
                    }
                    let findFilter=filterSubCategory.find(x=>x.value==item.subCategory)
                    if(!findFilter){
                        filterSubCategory.push(filtre) 
                    }
                }
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
        setFilterSubCategory(filterSubCategory);
        setUnchangedData(restData);
    }

    useEffect(() => {
        get();
    }, [module]);
console.log("Dataaaa ",data)
    useEffect(() => {
        get();
    }, [search]);

    const edit = (record) => {
        form.setFieldsValue({
          productName: '',
          age: '',
          target: '',
          ...record,
        });
        setEditingKey(record.key);
      };
    const cancel = () => {
        setEditingKey('');
      };
      const save = async (key) => {
        console.log("KEYYYYYYYYY    ",key)
        try {
          const row = await form.validateFields();
          const newData = [...data];
          const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            console.log("NewData     ",newData[index])
            setData(newData);
            setEditingKey('');
            if(newData[index] && newData[index]._id){
              let createdData={
                productCode:newData[index].productCode,
                productName:newData[index].productName,
                unitPrice:newData[index].unitPrice,
                wholesaler:newData[index].wholesaler,
                headers:newData[index].productName,
                C_D_OP:newData[index].C_D_OP,
                kgPrice:newData[index].kgPrice,
                boxPrice:newData[index].boxPrice
              }
                api.put("/rest/priceList/" + newData[index]._id, createdData).then(({ data: { result, result_message } }) => {
                  if (result_message.type === 'success') {
                    message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                  }
                  else
                    message.error(languagesCms.ERROR_SAVE_RECORD, 2);
                })
              
            }
          } else {
            console.log("burasıııı else")
            newData.push(row);
            setData(newData);
            setEditingKey('');
          }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
        }
      };

    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/priceList/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
    }
    const filter = (e) => {
      const val = e.target.value.toLocaleLowerCase();
      const tempList = Object.assign([], unchangedData);
      if (val === '' || !val) {
         setData(unchangedData)
         return;
      }
      let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
      setData(filteredData)
   }
  
   const isContainsFilterValue = (t, val) => {
      const isContains2 = t.header == null ? false : t.header.toLowerCase().indexOf(val) !== -1;
      // const isContains3 = t.createdAt == null ? false : t.createdAt.toLowerCase().indexOf(val) !== -1;
      // const isContains0 = !t.Users?  false : !t.Users.name?false:t.Users.name.toLowerCase().indexOf(val) !== -1;
     const isContains1 = !t.productCode ?  false : !t.productCode?false:t.productCode.toLowerCase().indexOf(val) !== -1;
      // const isContains4 = t.createdAt == null ? false : val.startsWith(moment(t.createdAt).format("DD-MM-YYYY HH:mm"));
      // let isContain5;
      // // let isContain6;
      // if(val.toLowerCase().indexOf('ysc')!=-1){
      //    isContain5=t.controlType == null ? false : t.controlType!==0;
      // }
      // if(val.toLowerCase().indexOf('silah')!=-1){
      //    isContain6=t.controlType == null ? false : t.controlType!==1;
  
      // }
      return (isContains1 || isContains2);
   }
  
   useEffect(() => {
      get({pageSize:10});
   }, [])
    const columns = [
        {
            title: "Ürün Kodu",
            dataIndex: 'productCode',
            sorter: (a, b) => a.productCode - b.productCode,
            fixed: 'left',
            sortDirections: ['descend', 'ascend']
        },
        {
          title: "Alt Kategori",
          dataIndex: 'subCategory',
          sorter: (a, b) => a.subCategory - b.subCategory,
          sortDirections: ['descend', 'ascend'],
          filters:filterSubCategory,
          onFilter: (value, record) =>( record.subCategory==value),

      },
        {
            title:"Tanım",
            dataIndex: 'productName',
            width: '15%',
            editable: true,
        },
        {
            title:"Birim Fiyat(TL)",
            dataIndex: 'unitPrice',
            sorter: (a, b) => a.unitPrice - b.unitPrice,
            sortDirections: ['descend', 'ascend'],
            editable: true,
        },
        {
            title: "KG Fiyat(TL)",
            dataIndex: 'kgPrice',
            sorter: (a, b) => a.kgPrice - b.kgPrice,
            sortDirections: ['descend', 'ascend'],
            editable: true,
        },
        {
            title: "Koli Fiyatı(TL)",
            dataIndex: 'boxPrice',
            sorter: (a, b) => a.boxPrice - b.boxPrice,
            sortDirections: ['descend', 'ascend'],
            editable: true,
        },
        {
      title: "C-D Tipi(%)",
            dataIndex: 'C_D_OP',
            sorter: (a, b) => a.C_D_OP - b.C_D_OP,
            sortDirections: ['descend', 'ascend'],
            editable: true,
        },
        {
        title: "WHS(%)",
        dataIndex: 'wholesaler',
        sorter: (a, b) => a.wholesaler - b.wholesaler,
        sortDirections: ['descend', 'ascend'],
        editable: true,
    },
    {
      title:"Güncelleme Tarihi",
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.updatedAt - b.updatedAt,
      render: (text) => moment(text).format("YYYY-MM-DD  HH:mm"),
  },
    {
        title: 'İşlemler',
        dataIndex: 'operation',
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Kaydet
              </Typography.Link>
              <Popconfirm title="İptal etmek istediğinizden emin misiniz?" onConfirm={cancel}>
                <a>Iptal</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Düzenle
            </Typography.Link>
          );
        },
      },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.dataIndex === 'age' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

      let downloadExcel = async () => {
        var option = {};
        let dataTable = [];
    
              let obj = {
                // year: "year",
                // month: "month",
                // dsrid: "dsrid",
                // dsrName: "dsrname",
                // totaltargetKg: "totaltargetkg",
              };
    
              dataTable.push(obj);
    
        let sheetAreas = [
          'productCode',
          'category',
          'segment',
          'subCategory',
          'productCode',
          'productName',
          'unit',
          'boxPieces',
          'boxWeight',
          'unitPrice',
          'kgPrice',
          'boxPrice',
          'C_D_OP',
          'wholesaler',
        ];
    
        option.fileName = 'priceList';
        option.datas = [
          {
            sheetData: dataTable,
            sheetName: 'sheet',
            sheetFilter: sheetAreas,
            sheetHeader: sheetAreas,
          },
        ];
    
        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      };

      let uploadExcel = async () => {
    
        let updateCount = 0;
        let insertCount = 0;
        if (excelData) {
            console.log("excelData     ",excelData)
            if(excelData.length>0){
              message.success(
                  'İşleminiz Devam Etmektedir. ',
                  2,
                );}
           let createdData=[]
          await Promise.all(excelData.map(async (i)=> {
            if(String(i.productCode)=="69654160"){
              console.log("DATA  ",i)
            }
            let data = {
              productCode: String(i.productCode),
              category: String(i.category),
              segment: String(i.segment),
              subCategory: String(i.subCategory),
              productName: String(i.productName),
              unit: String(i.unit),
              boxPieces: String(i.boxPieces),
              boxWeight: String(i.boxWeight.toFixed(2)),
              unitPrice: String(i.unitPrice.toFixed(2)),
              kgPrice: String(i.kgPrice.toFixed(2)),
              boxPrice: String(i.boxPrice.toFixed(2)),
              module:"infos3",
              active:true,
            //   piecesBox: String(i.boxPieces),
              C_D_OP: "%"+String(Math.round(Number(i.C_D_OP)*100)),
              wholesaler: "%"+String(Math.round(Number(i.wholesaler)*100)),
            };
            createdData.push(data)
              insertCount++;
            
          }))
          await api
          .post('/rest/uploadPriceList', {data:createdData})
          .then(({ data: { result, result_message } }) => {});
          if (insertCount) {
            message.error(
              'Excel Yüklendi. ' +
                updateCount +
                ' Güncellendi, ' +
                insertCount +
                ' Kayıt Eklendi.',
              2,
            );
          }
        }
      };
    
      useEffect(() => {
        uploadExcel();
      }, [excelData]);
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                {!isSmall && <ImportExcel setExcelData={setExcelData} />}
                {!isSmall && !user.distributorSAPCode && 
                  <Button
                  size={'large'}
                  onClick={downloadExcel}
                  style={{ marginRight: '5px' }}
                  icon={<DownloadOutlined />}
                >
                  {!isSmall && 'Export Excel'}
                </Button>}
                    {/* {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>)}
                    <Link to={(module ? module.refId : "") + "/add"}>
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>  */}
                </div>
            </div>
            <div className="table-wrap">
            <Search placeholder="Ara" onChange={filter}/>

                    <Form form={form} component={false}>
                <Table dataSource={data} 
                       bordered
                       columns={mergedColumns}
                       rowClassName="editable-row"
                       pagination={{
                           total: totalCount
                       }}
                       components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                       scroll={{
                        x: 1500,
                        y:440
                      }}

                       />
                           </Form>
            </div>

        </div>
    );
};

export default PriceList;
