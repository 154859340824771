import { Form, Table, Input, message, Button } from 'antd';
import React, { useEffect, useState, useGlobal, useRef, useContext } from "reactn";
import api from '../service/index'
import { useParams } from 'react-router-dom'
import styled from "styled-components";
import { NumericFormat } from 'react-number-format';

const EditableContext = React.createContext(null);
const TargetDetail = (props) => {
  let params = useParams()
  let id = params.id;
  let month = params.month;
  let year = params.year;
  let totaltarget = params.totaltarget.replace(".", "");
  let distributorSAPCode = params.distributorSAPCode;
  let dsrName = params.distributorName;
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [totalTargetData, setTotalTargetData] = useState();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const { Search } = Input;
  let [current, setCurrent] = useState(1);
  let [totalCount, setTotalCount] = useState(0)
  let [search, setSearch] = useState(false)
  let [loading, setLoading] = useState(false)
  let [visible, setVisible] = useState(null)
  let [total, setTotal] = useState(null)
  let [calculateNow, setCalculateNow] = useState(null)
  let [modules] = useGlobal("modules");
  let [isSmall] = useGlobal('isSmall');
  let [user,] = useGlobal("user");
  const inputRef = useRef(null);


  let [languagesCms] = useGlobal("languagesCms");

  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  }

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize }
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
      }
    }

    let _params = { sort: shortString, ...page, id: id };

    if (search.length > 2) {
      _params["search"] = search.toLowerCase();
      _params["searchFields"] = "title";
    }
    let restData = await api.get('/api/targetDsrList/' + month + "/" + year + "/" + distributorSAPCode).then(({ data }) => {
      if (data.result && data.result.getDsrAttendees) {
        setLoading(false)
        setTotalCount(data.result.total);
        setCurrent(data.result.page);
        setTotalTargetData(data.result.totalTarget);
        setVisible(data.result.visible);
        return data.result.getDsrAttendees.map((item, key) => {
          if(item.totaltargetkg){
            item.totaltargetkg=item.totaltargetkg.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
        }
          item.key = key;
          return item;
        })
      }
    });
    setData(restData);
  }
  const StyledTable = styled((props) => <Table {...props} />)`
  && tbody > tr:hover > td {
    background:#FFFFCE;
    cursor: pointer;
  }
  `;

  useEffect(() => {
    if (modules) {
      setLoading(true)
      get();
      const el=document.getElementById("target"+1);
      console.log("EL  ",el)
      //el.focus()
    }
  }, [])
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const form = useContext(EditableContext);


    useEffect(() => {
      console.log("Editing ",editing)
      if (editing) {
        console.log("input ref ",inputRef.current.props.id)
        inputRef.current.focus();
      }
    }, [editing]);


    const toggleEdit = async () => {
      setEditing(!editing);
      /*if (dataIndex == "target") {
        let calculatedPrecentage = Math.abs(Number(record[dataIndex])) !== 0 ? (Math.abs(Number(record[dataIndex])) / record.totaltargetkg) * 100 : 0
        record.percentage = "%" + calculatedPrecentage

      }
      else if (dataIndex == "percentage") {
        let calculatedTarget = Math.abs(Number(record[dataIndex])) !== 0 ? (Math.abs(Number(record.totaltargetkg)) * Math.abs(Number(record[dataIndex].replace("%", "")))) / 100 : 0
        record.target = String(calculatedTarget)
       /* if (!record.percentage.startsWith("%")) {
          record.percentage = "%" + record.percentage;
        }
      }*/
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
      record = record;
      let newDataTotalTarget = 0;
      await Promise.all(data.map(async (x, i) => {
        newDataTotalTarget += data[i].target ? Number(data[i].target) : 0

      }))
      setCalculateNow(newDataTotalTarget)
    };

    const saveFirst = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
    

    const onValuesChange = async (changedValues, allValues) => {
      const fieldName = Object.keys(changedValues)[0];
      if (fieldName == 'percentage') {
        const target = Math.abs(Number(allValues.percentage)) !== 0 ? (Math.abs(Number(record.totaltargetkg ? record.totaltargetkg.replace(".", "") : 0)) * Math.abs(Number(allValues.percentage.replace("%", "")))) / 100 : 0;

        record.target = String(target)
        record.percentage = String(allValues.percentage)
        form.setFieldsValue({target: target });
      }
      if (fieldName == 'target') {
        let calculatedPercentage = Math.abs(Number(allValues.target)) !== 0 ? (record.totaltargetkg && record.totaltargetkg!==0 ? (Math.abs(Number(allValues.target)) / record.totaltargetkg.replace(".", "")) : 1) * 100 : 0
        record.percentage = "%" + String(calculatedPercentage)

        record.percentage = record.percentage;

        form.setFieldsValue({ percentage: String(calculatedPercentage) });

      }
    };

    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form
          form={form}
          onValuesChange={onValuesChange}
          onClick={onValuesChange}
        >
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <Input ref={inputRef} type="number" pattern="[\d]{9}" min={0}
                      onKeyDown={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (!re.test(event.key) && event.key !== 'Backspace') {
                          event.preventDefault();
                        }
                        if(event.key=='Tab'){
                          saveFirst()
                        }
                      }}
              onPressEnter={saveFirst} onBlur={saveFirst} />
                        </Form.Item>
        </Form>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };


  const onChangeVeli = async (e,record) => {
    let val = String(e);
    val = val.replace(/\./g, "");
    val = val.replace(/\%/g, "");
    console.log("val",val);
  };



  const onChangePercentage = async (e,record,index) => {
    let val = String(e);
    let rr = record;
    console.log("Valll  ",val)
    console.log("record ",record)
    val = val.replace(/\./g, "");
    val = val.replace(/\%/g, "");



    const target = Math.abs(Number(val)) !== 0 ? 
    (Math.abs(Number(rr.totaltargetkg ? rr.totaltargetkg.replace(".", "") : 0)) * 
    Math.abs(Number(val))) / 100 : 0;
    console.log("TARGET   ",target)
    if(rr.target!=target){
      //record.target = String(target).replace(".",",")
      rr.target= String(target);
      rr.percentage= "%" + String(val);
      let dataN = data;
      dataN[index] = rr;
      console.log("dataN",dataN);
      setData([...dataN]);
    }
  };

  const onChangeTarget = async (e,record,index) => {
    let val = String(e);
    let rr = record;
    val = val.replace(/\./g, "");
    val = val.replace(/\%/g, "");

    

    let calculatedPercentage = Math.abs(Number(val)) !== 0 ? 
    (rr.totaltargetkg && rr.totaltargetkg!==0 ? 
      (Math.abs(Number(val)) / rr.totaltargetkg.replace(".", "")) : 1) * 100 : 0;

      calculatedPercentage = parseInt(calculatedPercentage);
      calculatedPercentage="%" + String(calculatedPercentage)
      
      if(record.percentage!=calculatedPercentage){
        rr.percentage =  String(calculatedPercentage)
         
          rr.target = String(val)
          let dataN = data;
          dataN[index] = rr;
          setData([...dataN]);
     }
  };
  console.log("visible   ",visible)
  useEffect(() => {
    if (data) {
      console.log("DATA   ",data)
      let newDataTotalTarget = 0;
      data.map(async (x, i) => {
        newDataTotalTarget += data[i].target ? Number(String(data[i].target).replace(",",".")) : 0
      
      })
      setCalculateNow(newDataTotalTarget)
    }
  }, [data])


  const columns = [
    {
      title: "Satıcı Id",
      dataIndex: 'attendee_id',
      width: '15%',
    },
    {
      title: "Satıcı",
      dataIndex: 'salesPerson',
      width: '30%',
    },
    {
      title: "Hedef",
      dataIndex: 'totaltargetkg',
      width: '15%',
    },
    {
      title: 'Yüzde',
      dataIndex: 'percentage',
      width: '15%',
      render: (value,record,index) => {
        let fakeIndex = index +1;
        return (
        <>
        
        <NumericFormat 
        tabIndex={fakeIndex}
        ref={(input) => { inputRef = input; }}
        id={"percentage"+fakeIndex}
        className='tableInput'
        defaultValue={value}
        displayType={'number'} 
        thousandSeparator={false} 
        disabled={visible}
        prefix={'%'} 
        onKeyDown={(event) => {
          const re = /^[0-9\b]+$/;
          if (!re.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
          }

        }}  
        onChange={async e=>{
          await onChangePercentage(e.target.value,record,index);
          document.getElementById("percentage"+fakeIndex).focus();
        }}
        //onChange={e=>onChangePercentage(e.target.value,record,index)}
        />
        </>
      )}
    },
    {
      title: 'Dağıtılmış Hedef',
      dataIndex: 'target',
      width: '15%',
      render: (value,record,index) => {
        let fakeIndex = index+1;
        return (
        <NumericFormat 
        tabIndex={fakeIndex}
        ref={(input) => { inputRef = input; }}
        id={"target"+fakeIndex}
        className='tableInput'
        defaultValue={value}
        displayType={'number'} 
        thousandSeparator="." 
        decimalSeparator=","
        disabled={visible}
        onKeyDown={(event) => {
          const re = /^[0-9\b]+$/;
          if (!re.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
          }

        }}       
         onChange={async e=> {
          await onChangeTarget(e.target.value,record,index);
          document.getElementById("target"+fakeIndex).focus();
        }}
        />
      )}
      //render: (text) => <span>{  parseFloat(text).toLocaleString()}</span>
    },
  ];

  const handleSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setData(newData);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const save = async () => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      let createData = []

      if (newData) {
        let newDataTotalTarget = 0;
        await Promise.all(newData.map(async (x, i) => {
          newDataTotalTarget += newData[i].target ? Number(newData[i].target) : 0
          let createdData = {
            target_id: newData[i].target_id || null,
            target: newData[i].target,
            percentage: newData[i].percentage,
            attendeeId: newData[i].attendee_id,
            realized: newData[i].realized,
            totalTargetKg: String(newData[i].totaltargetkg),
            totalTargetData: Number(totaltarget),
            newDataTotalTarget: newDataTotalTarget,
            month: month,
            year: year,
            type: 'dsr',
          }
          createData.push(createdData)
        }))
        setTotal(createData[data.length - 1].newDataTotalTarget)
        if (createData[data.length - 1].newDataTotalTarget && createData[data.length - 1].newDataTotalTarget == totaltarget) {
          message.success("Değişiklikleriniz Başarılı Olarak Kaydedilmiştir.", 2);
        }
        await api.post("/api/createData", { data: createData }).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'error') {
            message.error(result_message.message, 2);
          } else {
            message.success("Değişiklikleriniz Başarılı Olarak Kaydedilmiştir.", 2);
            window.location.reload(false);
          }
        })

      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  return (

    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{dsrName} Kullanıcısının {year} Yılı {month}. Ayı İçin Toplam Hedef = {totaltarget.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")}</h1>
            <p style={{ fontSize: "12px", fontWeight: "bold", color: "#FFFFCE" }}>Dağıtmış olduğunuz hedef değeri = {calculateNow ? calculateNow : totaltarget}</p>
        </div>
        {!visible &&
        <div className="list-buttons">
          <Button type="light" onClick={() => save()}
            size="large">{isSmall ? '' : "KAYDET"}</Button>
        </div>
        }

      </div>
      <div className="table-wrap">
      <Form
          form={form}
        >
          <Form.Item
            style={{
              margin: 0,
            }}
            name={"Data"}
          >
        <StyledTable
          //rowClassName={() => 'editable-row'}
          // components={{
          //   body: {
          //     row: EditableRow,
          //     cell: EditableCell,
          //   },
          // }}
          bordered
          pagination={false} 
          dataSource={data}
          columns={columns}
        />
                                </Form.Item>
        </Form>

      </div>
    </div>

  );
};
export default TargetDetail;