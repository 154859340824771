import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm,message} from 'antd';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import { ImportExcel } from './../components';


import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const RealizedTarget = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [unchangedData, setUnchangedData] = useState([])
    let [excelData, setExcelData] = useState([]);
    let [totalCount, setTotalCount] = useState(0);
    let [filterRtmType, setFilterRtmType] = useState([]);
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [modules] = useGlobal("modules");
    let [isSmall] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 
    let [currentPage, setCurrentPage] = useState(1);
    let [pageSize, setPageSize] = useState(10);


    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        setCurrentPage(page.current);

        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 1000000};
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "category";
        }
        let filterRtmType=[]
        let restData = await api.get('/api/realizedTargets', {_params}).then(({data}) => {
            console.log("data   ",data)
            if (data && data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                if(data.result.length>0){
                return data.result.map((item, key) => {
                    if(item.rtmType){
                        let filtre={
                                text: item.rtmType,
                                value: item.rtmType, 
                        }
                        let findFilter=filterRtmType.find(x=>x.value==item.rtmType)
                        if(!findFilter){
                            filterRtmType.push(filtre) 
                        }
                    }
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    item.key = key;
                    return item;
                })}
            }
            
        });
        setData(restData);
        setFilterRtmType(filterRtmType);
        setUnchangedData(restData)
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }
    let uploadExcel = async () => {
        let newData;
    
        let updateCount = 0;
        let insertCount = 0;
    
        if (excelData) {
          for (let i in excelData) { 
            newData = {
              year: String(excelData[i].year) || '',
              month: String(excelData[i].month) || '',
              dsrid: String(excelData[i].dsrid) || '',
              category: String(excelData[i].category) || '',
              realizedkg: Number(excelData[i].realizedkg) || '',
              tkpoint: Number(excelData[i].tkpoint) || '',
              realizedkgpoint: Number(excelData[i].realizedkgpoint) || '',
            };
   console.log("dataaaa     ",newData)

               await api
                .post('/rest/realizedTargets', newData)
                .then(({ data: { result, result_message } }) => {});
              insertCount++;   

            
          }
          if (updateCount || insertCount) {
            message.error(
              'Excel Yüklendi. ' +
                updateCount +
                ' Güncellendi, ' +
                insertCount +
                ' Kayıt Eklendi.',
              2,
            );
          }
        }
      };
    
      useEffect(() => {
        uploadExcel();
      }, [excelData]);
      const filter = (e) => {
        const val = e.target.value.toLocaleLowerCase();
        const tempList = Object.assign([], unchangedData);
        if (val === '' || !val) {
           setData(unchangedData)
           return;
        }
        let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
        setData(filteredData)
     }
    
     const isContainsFilterValue = (t, val) => {
        const isContains2 = t.dsrName == null ? false : t.dsrName.toLocaleLowerCase('tr').indexOf(val) !== -1;
        // const isContains3 = t.createdAt == null ? false : t.createdAt.toLowerCase().indexOf(val) !== -1;
        // const isContains0 = !t.Users?  false : !t.Users.name?false:t.Users.name.toLowerCase().indexOf(val) !== -1;
       //const isContains1 = !t.productCode ?  false : !t.productCode?false:t.productCode.toLowerCase().indexOf(val) !== -1;
        // const isContains4 = t.createdAt == null ? false : val.startsWith(moment(t.createdAt).format("DD-MM-YYYY HH:mm"));
        // let isContain5;
        // // let isContain6;
        // if(val.toLowerCase().indexOf('ysc')!=-1){
        //    isContain5=t.controlType == null ? false : t.controlType!==0;
        // }
        // if(val.toLowerCase().indexOf('silah')!=-1){
        //    isContain6=t.controlType == null ? false : t.controlType!==1;
    
        // }
        return (isContains2);
     }
    
     useEffect(() => {
        get({pageSize:10});
     }, [])
    let columns = [
        {
            title:"Yıl",
            dataIndex:'year',
            key:'year',
            sorter:(a,b)=>a.year-b.year,
            dortDirections:['descend','ascend']
        },
        {
      title: "Ay",
            dataIndex: 'month',
            key: 'month',
            sorter: (a, b) => a.month- b.month,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title:"RTMTYPE",
            dataIndex: 'rtmType',
            key: 'rtmType',
            sorter: (a, b) => a.rtmType - b.rtmType,
            sortDirections: ['descend', 'ascend'],
            filters:filterRtmType,
            onFilter: (value, record) =>( record.rtmType==value),
  
        },
        {
            title:"Dsr",
            dataIndex: 'dsrName',
            key: 'dsrName',
            sorter: (a, b) => a.dsrName - b.dsrName,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:"Kategori",
            dataIndex: 'category',
            key: 'category',
            sorter: (a, b) => a.category - b.category,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:"Gerçekleşen KG",
            dataIndex: 'realizedkg',
            key: 'realizedkg',
            sorter: (a, b) => a.realizedkg - b.realizedkg,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:"Çarpan",
            dataIndex: 'tkpoint',
            key: 'tkpoint',
            sorter: (a, b) => a.tkpoint - b.tkpoint,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:"Gerçekleşen KG Puan",
            dataIndex: 'realizedkgpoint',
            key: 'realizedkgpoint',
            sorter: (a, b) => a.realizedkgpoint - b.realizedkgpoint,
            sortDirections: ['descend', 'ascend']
        }
        // {
        //     title: 'İşlemler',
        //     key: 'action',
        //     className: 'editColumn',
        //     width: 150,
        //     render: (text, record) => (
        //         <Space size="small">
        //             <Link to={"/targetpoint/edit/month/" + record._id.month+"/year/"+record._id.year+"/totalpoint/"+record.totalactkgpoint}>
        //                 <Button type="primary" ghost icon={<EditOutlined/>}>
        //                     {"Puan Detayını Gör & Puan Dağıt"}
        //                 </Button>
        //             </Link>
        //             {/* <Popconfirm
        //                 onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
        //                 okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
        //                 <Button type="danger" icon={<DeleteOutlined/>}>
        //                     {isSmall ? '' : languagesCms.DELETE}
        //                 </Button>
        //             </Popconfirm> */}
        //         </Space>
        //     ),
        // },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons"> {/*
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}*/}
          {/* {!isSmall && <ImportExcel setExcelData={setExcelData} />} */}

                </div> 
            </div>

            <div className="table-wrap">
            <Search placeholder="Ara" onChange={filter}/>

                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       pagination={{
                        total: totalCount
                    }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default RealizedTarget;
