import React, { useEffect, useState, useGlobal } from "reactn";
import { Table, Space, Button, Input, Popconfirm, message } from 'antd';
import moment from 'moment';
import api from '../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';
import { ImportExcel } from './../components';


import {
    EditOutlined,
    DeleteOutlined,
    FileExcelOutlined,
    CheckOutlined,
    CloseOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const TargetPoint = (props) => {

    const { Search } = Input;
    let [data, setData] = useState([]);
    let [unchangedData, setUnchangedData] = useState([])
    let [filterMonth, setFilterMonth] = useState([]);
    let [filterYear, setFilterYear] = useState([]);
    let [excelData, setExcelData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [user,] = useGlobal("user");
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [modules] = useGlobal("modules");
    let [isSmall] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms");

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = { page: page.current, pageSize: page.pageSize }
        } else {
            page = { page: 1, pageSize: 10 };
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = { sort: shortString, ...page, };

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "title";
        }
        let filterMonth = [];
        let filterYear = [];

        let restData = await api.get('/api/getMyTargetPoint', { _params }).then(({ data }) => {
            console.log("data   ", data)
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.map((item, key) => {
                    user.distributorName ? item.distributorName = user.distributorName : item.distributorName = item.distributorName
                    console.log("item.distributorName  ",item.distributorName)
                    if (item.totalactkgpoint) {
                        item.totalactkgpoint = item.totalactkgpoint.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
                    }
                    if (item.month) {
                        let filtre = {
                            text: item.month,
                            value: item.month,
                        }
                        let findFilter = filterMonth.find(x => x.value == item.month)
                        if (!findFilter) {
                            filterMonth.push(filtre)
                        }
                    }
                    if (item.year) {
                        let filtre = {
                            text: item.year,
                            value: item.year,
                        }
                        let findFilter = filterYear.find(x => x.value == item.year)
                        if (!findFilter) {
                            filterYear.push(filtre)
                        }
                    }
                    if (item.active)
                        item.active = <CheckOutlined />;
                    else
                        item.active = <CloseOutlined />;
                    item.key = key;
                    return item;
                })
            }

        });
        setData(restData);
        setUnchangedData(restData)
        setFilterMonth(filterMonth);
        setFilterYear(filterYear);

    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let getReport = async () => {
        //let lang = "TR";

        let result = await api.get(
            `/rest/reports/targetPoints`,
        );
        const file = new Blob(['\ufeff', result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${"points".replace(/ /g, '')}_report.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };
    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }
    let uploadExcel = async () => {
        let newData;

        let updateCount = 0;
        let insertCount = 0;

        if (excelData) {
            if (excelData.length > 0) {
                message.success(
                    'İşleminiz Devam Etmektedir. ',
                    2,
                );
            }
            for (let i in excelData) {
                newData = {
                    year: String(excelData[i].year) || '',
                    month: String(excelData[i].month) || '',
                    dsrid: String(excelData[i].dsrid) || '',
                    category: String(excelData[i].category) || '',
                    rtmType: String(excelData[i].rtmtype || ''),
                    actkg: Number(excelData[i].actkg) || '',
                    tkpoint: Number(excelData[i].tkpoint) || '',
                    actkgpoint: Number(excelData[i].actkgpoint) || '',
                };

                await api
                    .post('/rest/incomingPoints', newData)
                    .then(({ data: { result, result_message } }) => { });
                insertCount++;


            }
            if (updateCount || insertCount) {
                message.error(
                    'Excel Yüklendi. ' +
                    updateCount +
                    ' Güncellendi, ' +
                    insertCount +
                    ' Kayıt Eklendi.',
                    2,
                );
            }
        }
    };

    useEffect(() => {
        uploadExcel();
    }, [excelData]);
    const filter = (e) => {
        const val = e.target.value.toLocaleLowerCase();
        const tempList = Object.assign([], unchangedData);
        if (val === '' || !val) {
            setData(unchangedData)
            return;
        }
        let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
        setData(filteredData)
    }

    const isContainsFilterValue = (t, val) => {
        const isContains2 = t.distributorName == null ? false : t.distributorName.toLowerCase().indexOf(val) !== -1;
        return isContains2;
    }

    useEffect(() => {
        get();
    }, [])
    let columns = [
        {
            title: "Yıl",
            dataIndex: 'year',
            key: 'year',
            sorter: (a, b) => a.year - b.year,
            dortDirections: ['descend', 'ascend'],
            filters: filterYear,
            onFilter: (value, record) => record.year.includes(value),
        },
        {
            title: "Ay",
            dataIndex: 'month',
            key: 'month',
            sorter: (a, b) => a.month - b.month,
            sortDirections: ['descend', 'ascend'],
            filters: filterMonth,
            onFilter: (value, record) => record.month.includes(value),
        },
        {
            title: "Distribütör",
            dataIndex: 'distributorName',
            key: 'distributorName',
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Puan",
            dataIndex: 'totalactkgpoint',
            key: 'totalactkgpoint',
            sorter: (a, b) => a.totalactkgpoint - b.totalactkgpoint,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'İşlemler',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    {/* <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => getReport(record)}
          > 
            {isSmall ? '' : languagesCms.REPORT}
          </Button> */}
                    <Link to={"/targetpoint/edit/month/" + record._id.month + "/year/" + record._id.year + "/totalpoint/" + record.totalactkgpoint + "/distributorSAPCode/" + (record.distributorSAPCode ? record.distributorSAPCode : null) + "/dsrName/" + record.distributorName}>
                        <Button type="primary" ghost icon={<EditOutlined />}>
                            {"Puan Detayını Gör & Puan Dağıt"}
                        </Button>
                    </Link>

                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>

                <div className="list-buttons">
                    {!user.distributorSAPCode &&
                        <Button
                            type="primary"
                            size="large"
                            icon={<FileExcelOutlined />}
                            onClick={() => getReport()}
                        >
                            {isSmall ? '' : "Export Excel"}
                        </Button>
                    }


                    {!isSmall && !user.distributorSAPCode && user.role == "superAdmin" && <ImportExcel setExcelData={setExcelData} />}

                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder="Ara" onChange={filter} />

                <Table dataSource={data} columns={columns}
                    loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "large" }}
                    onChange={handleTableChange}
                    pagination={{
                        total: totalCount
                    }}
                    rowSelection={{ ...rowSelection }} />
            </div>

        </div>
    );
};


export default TargetPoint;
